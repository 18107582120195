function Dashboard(){


return(
<div className='W100vw H100vh displayFlex flexDirectionColumn alignItemsCenter justifyContentCenter backgroundBlack'>

<p className='motoFontSize colorGreen0 fontWeightBold'>DashBoard</p>

</div>);
}
export default Dashboard;