import {useRef,useState} from 'react';
import {Link,useNavigate} from "react-router-dom";

function Login(){

    const loginComponentRef=useRef(null);
    const emailInputComponentRef=useRef(null);
    const passwordInputComponentRef=useRef(null);
    const loginButtonComponentRef=useRef(null);
    const [passwordVisibility,setPasswordVisibility]=useState('password');
    const [passwordVisibilityIcon,setPasswordVisibilityIcon]=useState('/visibility_off.svg');
    const loginStatusRef=useRef(null);
    const [loginStatusMessage,setLoginStatusMessage]=useState('');
    const [signUpLink,setSignUpLink]=useState((<></>));
    const navigate=useNavigate();


    function passwordVisibilityToggle(){
        if(passwordVisibility==='password'){
            setPasswordVisibility('text');
            setPasswordVisibilityIcon('/visibility.svg');
        }else{
            setPasswordVisibility('password');
            setPasswordVisibilityIcon('/visibility_off.svg');
        }
    }

    function loginSubmission(){
        const emailAddress=emailInputComponentRef.current.value;
        const password=passwordInputComponentRef.current.value;
        const emailRegex=/^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const passwordRegex=/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        const urlToConnect='https://alterux.in/loginSubmission';




        if(!emailRegex.test(emailAddress)){
            setLoginStatusMessage('Invalid Email Format');
        }else if(!passwordRegex.test(password)){
            setLoginStatusMessage('Password should have at least 8 characters, including 1 uppercase, 1 lowercase, 1 number, and 1 special character (@$!%*?&).');
        }else {
        fetch(urlToConnect,{method:'PUT',headers:{'Content-Type':'application/json'},body:JSON.stringify({'emailAddress':emailAddress,'password':password})})
        .then(response=>{return response.text()})
        .then(responseText=>{
            if(responseText==='successful'){
                navigate('/dashboard');
            }else if(responseText==='sign up'){
            setLoginStatusMessage('You do not have an account with us.');
            setSignUpLink((<Link className='colorGreen0 minimalMargin' to='/signup'>Sign Up</Link>));
        }else if(responseText==='wrong password'){
            setLoginStatusMessage('Please recheck the entered password.');
        }else {
            setLoginStatusMessage('Sorry, we are having an issue. Please retry after some time.');
        }
        });
    }

    }


    return (<div ref={loginComponentRef} className='W100vw H100vh displayFlex flexDirectionColumn alignItemsCenter justifyContentCenter'>
    
        <div className={'displayFlex flexDirectionColumn'}> 
        <input ref={emailInputComponentRef} className={'signUpInput textAlignCenter placeHolderOpacity borderStyleSolid'} type='text' minLength={8} placeholder='Email address'></input>
           
            <div className='displayFlex alignItemsCenter justifyContentCenter'>
                <input ref={passwordInputComponentRef} className={'signUpInput textAlignCenter placeHolderOpacity borderStyleSolid'} type={passwordVisibility} minLength={8} maxLength={16} placeholder='Password'></input>
                <span onClick={passwordVisibilityToggle}><img className={'W5vw H5vh'} src={passwordVisibilityIcon}></img></span>
                </div>
            </div>
    
        <button ref={loginButtonComponentRef} className='signUpButton minimalPadding minimalMargin fontWeightBold borderStyleSolid borderRadius1vw colorBlack backgroundGreen0' onClick={loginSubmission}>Log in</button>
        
        <p ref={loginStatusRef} className='W100vw displayFlex flexDirectionColumn signUpStatus minimalMargin fontWeightBold textAlignCenter'>{loginStatusMessage}{signUpLink}</p>
    

    </div>);
}

export default Login;