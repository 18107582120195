//import {useState} from 'react';
//import logo from './logo.svg';
import { useRef, useState } from 'react';
import { useEffect } from 'react';
import React from 'react';
import './App.css';
import {Link, Route, Routes } from 'react-router-dom';
import ModelViewer from "./ModelObject";
import SignUp from "./SignUp";
import Login from "./Login";
import Dashboard from './Dashboard';


function App() {

  var [isMobile,setIsMobile]=useState(window.innerHeight>window.innerWidth);

  useEffect(()=>{
    
    const screenChange=()=>{
      setIsMobile(window.innerHeight>window.innerWidth);
    }

    window.addEventListener('resize',screenChange);

    return ()=>{window.removeEventListener('resize',screenChange);};
  },[]);

  const [menuItemsVisiblity,setMenuItemsVisibility]=useState('displayNone');
  const [activeItem,setActiveItem]=useState(window.location.pathname.substring(1)===''?'home':window.location.pathname.substring(1));

  return (
    <div className="backgroundBlue0 colorWhite overflowXHidden">
            <div className='displayFlex alignItemsCenter backgroundWhite'>
        <div className={isMobile?'W90vw displayFlex alignItemsCenter':'displayFlex alignItemsCenter'}>
        <Link onClick={()=>setActiveItem('home')} className='navMenuBranding textDecorationNone backgroundWhite displayFlex alignItemsCenter minimalPadding' to='/'><img className='logoSize' src='/logo_3.png'></img><span className='colorWhite backgroundBlack fontWeightBold logoFontSize'>ALTER</span><span className='backgroundBlack fontWeightBold colorGreen0 logoFontSize'>UX</span></Link>
        {/* <img className='logoSize' src='/logo_1.png'></img> */}
        </div>
        {!isMobile && <NavigationBar activeItem={activeItem} setActiveItem={setActiveItem}/>}
        {isMobile && <NavigationMenu menuItemsVisiblity={menuItemsVisiblity} setMenuItemsVisibility={setMenuItemsVisibility}/>}
        </div>

        <NavigationMenuItems activeItem={activeItem} setActiveItem={setActiveItem} menuItemsVisiblity={menuItemsVisiblity} setMenuItemsVisibility={setMenuItemsVisibility}/>

        <Routes>
          <Route path='/' element={<Home isMobile={isMobile}/>}/>
          <Route path='/contact' element={<Contact/>}/>
          <Route path='/about' element={<About/>}/>
          <Route path='/learn' element={<Learn/>}/>
          <Route path='/projects' element={<Projects/>}/>
          <Route path='/upload' element={<Upload/>}/>
          <Route path='/signup' element={<SignUp/>}/>
          <Route path='/login' element={<Login/>}/>
          <Route path='/dashboard' element={<Dashboard/>}/>
        </Routes>
    </div>
  );
}

export default App;


function Home({isMobile}){

  const carouselElementRef=useRef(null);
  const carouselIndicatorDotsRef=useRef(null);

  const [backgroundColorOnHover,setBackgroundColorOnHover]=useState('backgroundWhite');

  const sectorRelating=useRef(null);

  const sectorRelatingArray=['BUSINESS','CAUSE','REACH'];
  const sectorCurrentPosition=useRef(0);
  const sectorMaxCount=3;

  useEffect(()=>{
    
    // const toggleFunction=()=>{setSectorRelating(sectorRelating==='business'?'cause':'business');};

    const toggleFunction=()=>{

      sectorCurrentPosition.current++;
      
      sectorCurrentPosition.current=(sectorCurrentPosition.current===sectorMaxCount?0:sectorCurrentPosition.current);

      sectorRelating.current.textContent=sectorRelatingArray[sectorCurrentPosition.current];

      sectorRelating.current.classList.toggle('fadeInOut0');
      sectorRelating.current.classList.toggle('fadeInOut1');

    };
    
   const timedInterval=setInterval(toggleFunction,4000);

    return ()=>clearInterval(timedInterval);
  },[sectorRelating]);

  
  var [currentSlide,setCurrentSlide]=useState(0);
  const slides=[<Slide0/>,<Slide1/>];

  return(
    <>

    <div className='displayFlex flexDirectionColumn justifyContentCenter alignItemsCenter'>



    <div className={'W100vw H50vh displayFlex justifyContentCenter alignItemsCenter'}>

        <p className='W45vw textAlignRight motoFontSize colorGreen0'><b>GLOBALIZE&nbsp;</b></p>
        <p className={'W55vw textAlignLeft motoFontSize'}><b>YOUR <span className='fadeInOut0' ref={sectorRelating}>BUSINESS</span></b></p>
    

  </div>

<Carousel carouselElementRef={carouselElementRef} carouselIndicatorDotsRef={carouselIndicatorDotsRef} isMobile={isMobile} slides={slides} maxSlides={1} currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} backgroundColorOnHover={backgroundColorOnHover} setBackgroundColorOnHover={setBackgroundColorOnHover}/>
<CarouselIndicatorDots carouselIndicatorDotsRef={carouselIndicatorDotsRef} currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} circleCount={2} backgroundColorOnHover={backgroundColorOnHover}/>

<div className={'W100vw H50vh backgroundBlack displayFlex flexDirectionColumn justifyContentCenter alignItemsCenter'}>
<p className='fontWeightBold readableFontSize minimalPadding textAlignCenter margin0'>Transform your static website into something truly engaging for your customers.</p>
<p className='fontWeightBold readableFontSize minimalPadding textAlignCenter margin0'>Let's create an interactive platform that captivates and connects with them effectively.</p>
</div>

<div className={'W100vw H50vh backgroundWhite displayFlex flexDirectionColumn justifyContentCenter alignItemsCenter'}>
<h1 className='colorBlack'>3D Interactive Designs</h1>
  <div className={'W50vw displayFlex flexDirectionColumn justifyContentCenter alignItemsCenter'}>
<ModelViewer/>
</div>
</div>

</div>


</>
  );
}


function Contact(){
  return(<div className='H100vh W100vw displayFlex flexDirectionColumn justifyContentCenter alignItemsCenter'>
    <a className='colorWhite textDecorationUnderlineColorGreen0' href='mailto:shivarajkumar.moria@gmail.com'><h1>Email</h1></a>
    <a className='colorWhite textDecorationUnderlineColorGreen0' href='https://www.linkedin.com/in/shivarajkumar-m'><h1>LinkedIn</h1></a>
  </div>);
}

function About(){
  return(<div className='H100vh displayFlex flexDirectionColumn justifyContentCenter alignItemsCenter minimalPadding'>

    <div className='textAlignCenter minimalPadding backgroundBlack borderRadius1vw'>
    <p className='readableFontSize'>Founded by a <span className='colorEffect fontWeightBold'>Software Engineer</span></p>
    <p className='readableFontSize minimalPadding'><Link className='colorWhite textDecorationUnderlineColorGreen0' to='/contact'>Connect</Link> with us and get your business on a</p>
    <p className='readableFontSize colorBlue0'><b>.COM</b></p>
    </div>

    </div>);
}

function Learn(){
  return(
  <div className='H100vh W100vw displayFlex justifyContentCenter alignItemsCenter'>
    <div>
  <h1>Learn</h1>
  <p>Add a page that teaches</p>
  </div>
  </div>
  );
}
function Projects(){

  return(
  <div className='H100vh W100vw displayFlex justifyContentCenter alignItemsCenter'>
    <div className='textAlignCenter'>
  <h1>Cipher</h1>
  <p className='readableFontSize minimalPadding'>A project aimed at securing private data</p>
  <a href="http://alterux.online/cipher">Click here to visit</a>
  </div>
  </div>
  );
}

function Upload(){
  
  return(
    <div className='H100vh W100vw displayFlex justifyContentCenter alignItemsCenter'>
    <div>
      <form method='post' action='/uploadfile' enctype='multipart/form-data'>
      <input type='file' name='fileName'></input>
      <input type='password' name='passwordName'></input>
      <input type='submit' value='Upload'></input>
      </form>
  </div>
  </div>
  );

}


function NavigationMenu({menuItemsVisiblity,setMenuItemsVisibility}){

  function expandMenu(){
    menuItemsVisiblity==='displayNone'?setMenuItemsVisibility('displayBlock'):setMenuItemsVisibility('displayNone');
  }

  return (
    <div>
        <button onClick={expandMenu} className='menuButton'><img className='menuImage' src='menuIcon.svg' alt='Menu'></img></button>
        </div>
  );
}


// Mobile Navigation
function NavigationMenuItems({activeItem,setActiveItem,menuItemsVisiblity,setMenuItemsVisibility}){
  function menuItemClicked(activeItemName){
    setActiveItem(activeItemName);
    menuItemsVisiblity==='displayNone'?setMenuItemsVisibility('displayBlock'):setMenuItemsVisibility('displayNone');
    
  }

  return (
  <nav className={menuItemsVisiblity+' backgroundColorWhite80Per displayFlex flexDirectionColumn textAlignCenter borderRadius1vw'}>
    <Link onClick={()=>menuItemClicked('home')} className={(activeItem==='home'?'colorBlack50Per ':'colorBlack ')+menuItemsVisiblity+' textDecorationNone minimalPadding'} to='/'>Home</Link>
    <Link onClick={()=>menuItemClicked('contact')} className={(activeItem==='contact'?'colorBlack50Per ':'colorBlack ')+menuItemsVisiblity+' textDecorationNone minimalPadding'} to='/contact'>Contact</Link>
    <Link onClick={()=>menuItemClicked('about')} className={(activeItem==='about'?'colorBlack50Per ':'colorBlack ')+menuItemsVisiblity+' textDecorationNone minimalPadding'} to='/about'>About</Link>
    <Link onClick={()=>menuItemClicked('learn')} className={(activeItem==='learn'?'colorBlack50Per ':'colorBlack ')+menuItemsVisiblity+' textDecorationNone minimalPadding'} to='/learn'>Learn</Link>
    <Link onClick={()=>menuItemClicked('projects')} className={(activeItem==='projects'?'colorBlack50Per ':'colorBlack ')+menuItemsVisiblity+' textDecorationNone minimalPadding'} to='/projects'>Projects</Link>
  </nav>);
}


// Desktop Navigation
function NavigationBar({activeItem,setActiveItem}){

  function menuItemClicked(activeItemName){
    setActiveItem(activeItemName);
  }

return (
  <nav className='displayFlex alignItemsCenter'>
    <Link onClick={()=>menuItemClicked('home')} className={(activeItem==='home'?'colorBlack50Per ':'colorBlack ')+'textDecorationNone minimalPadding'} to='/'>Home</Link>
    <Link onClick={()=>menuItemClicked('contact')} className={(activeItem==='contact'?'colorBlack50Per ':'colorBlack ')+'textDecorationNone minimalPadding'} to='/contact'>Contact</Link>
    <Link onClick={()=>menuItemClicked('about')} className={(activeItem==='about'?'colorBlack50Per ':'colorBlack ')+'textDecorationNone minimalPadding'} to='/about'>About</Link>
    <Link onClick={()=>menuItemClicked('learn')} className={(activeItem==='learn'?'colorBlack50Per ':'colorBlack ')+'textDecorationNone minimalPadding'} to='/learn'>Learn</Link>
    <Link onClick={()=>menuItemClicked('projects')} className={(activeItem==='projects'?'colorBlack50Per ':'colorBlack ')+'textDecorationNone minimalPadding'} to='/projects'>Projects</Link>
  </nav>
);
}






function Slide0(){

  return (<div className={'displayFlex flexDirectionColumn justifyContentCenter alignItemsCenter userSelectNone'}>
    <p className='colorGray motoFontSize fontWeightBold'>Lets get there!</p>
  </div>);
}

function Slide1(){

  return (<div className='minimalPaddingXTop displayFlex flexDirectionColumn justifyContentCenter alignItemsCenter'>
       <div className='minimalPaddingXTop displayFlex flexDirectionColumn alignItemsCenter borderRadius1vw backgroundWhite borderStyleSolid borderColorBlack'>
       <h1 className='W100Per textAlignCenter colorBlack backgroundWheat minimalPadding marginTop0 borderRadius1vw'>What we do?</h1>
       <div className='displayFlex alignItemsCenter'>
         <ul className='colorBlack fontWeightBold readableFontSize margin0 listStyleSquare'>
         <li className='readableFontSize minimalPaddingXLeftXRight'>We specialize in designing, building, and maintaining custom web applications.</li>
           <li className='readableFontSize minimalPaddingXLeftXRight'>Businesses can choose a domain name that aligns with their brand.</li>
           <li className='readableFontSize minimalPaddingXLeftXRight'><Link className='colorBlack' to='/contact'>Connect</Link> with us for custom web applications tailored to your business needs.</li>
         </ul>
         </div>
         </div>
         </div>);
}



function Carousel({carouselElementRef,carouselIndicatorDotsRef,isMobile,slides,maxSlides,currentSlide,setCurrentSlide,backgroundColorOnHover,setBackgroundColorOnHover}){

  const currentSlideRef=useRef(0);

  function forward(){
    if(currentSlide===maxSlides)setCurrentSlide(0);else setCurrentSlide(currentSlide+=1);
  }

  function backward(){
    if(currentSlide===0)setCurrentSlide(maxSlides);else setCurrentSlide(currentSlide-=1);
  }

  var pointerStart;
  var pointerEnd;
  let pointerTracking=true;
  const pointerDown0=(event)=>{
    pointerTracking=true;
    pointerStart=event.clientX;
  };

  const pointerMove0=(event)=>{
    if(pointerTracking)currentSlideRef.current.style.transform=`translate(${-(pointerStart-event.clientX)}px, 0)`;
  };

  const pointerUp0=(event)=>{
    pointerTracking=false;
    pointerEnd=event.clientX;
    pointerEnd!==0&&swipeHandling();
  };

  function swipeHandling(){
    pointerStart>pointerEnd&&(Math.abs(pointerStart-pointerEnd)>(isMobile?100:350)) && forward();
    pointerStart<pointerEnd&&(Math.abs(pointerStart-pointerEnd)>(isMobile?100:350)) && backward();
    currentSlideRef.current.style.transform='translate(0, 0)';
  }

  const pointerLeave0=()=>{
    pointerTracking=false;
    currentSlideRef.current.style.transform='translate(0, 0)';
  };
  
  const pointerEnter0=()=>{
    carouselElementRef.current.classList.add('backgroundColorFadeIn'); 
    carouselIndicatorDotsRef.current.classList.add('backgroundColorFadeIn'); 
    document.documentElement.style.setProperty('--backgroundColorInput',`rgb(245, 222, 179)`); 
    setBackgroundColorOnHover('backgroundColorFadeIn');
  };

  const mouseLeave0=()=>{
    carouselElementRef.current.classList.remove('backgroundColorFadeIn'); 
    carouselIndicatorDotsRef.current.classList.remove('backgroundColorFadeIn');
  };

  return (
      <div ref={carouselElementRef} onPointerEnter={pointerEnter0} onMouseLeave={mouseLeave0} onPointerLeave={pointerLeave0} onPointerDown={pointerDown0} onPointerUp={pointerUp0} onPointerMove={pointerMove0} className={`W100vw H50vh displayFlex flexDirectionColumn justifyContentCenter alignItemsCenter minimalPadding userSelectNone touchActionPanY backgroundWhite ${backgroundColorOnHover}`}>
      
      <div ref={currentSlideRef} className='W100vw displayFlex justifyContentCenter alignItemsCenter minimalPadding'>
  {slides[currentSlide]}
        </div>

        </div>);
}


function CarouselIndicatorDots({carouselIndicatorDotsRef,currentSlide,setCurrentSlide,circleCount,backgroundColorOnHover}){

  let createCarouselIndicatorDots=[];

  function circleButtonClicked(circleButtonNo){
    setCurrentSlide(circleButtonNo);
  }

  for(let i=0;i<circleCount;i++){createCarouselIndicatorDots.push(<button key={i} onClick={()=>circleButtonClicked(i)} className={'circleButton minimalMargin '+(currentSlide===i?'backgroundBlack':'backgroundWhite')+' borderStyleSolid'}></button>)}

  return(<div ref={carouselIndicatorDotsRef} className={`W100vw displayFlex justifyContentCenter alignItemsCenter backgroundWhite ${backgroundColorOnHover}`}>
    {createCarouselIndicatorDots}
    </div>);
}