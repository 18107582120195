// 3DModel.js
import React from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import Model from './TestThreeD';

// Replace '/path-to-your-file.glb' with the path to your .glb file


// const ModelViewer = () => {
//   return (
//     <Canvas>
//       <Suspense fallback={null}>
//         <ambientLight intensity={0.5} />
//         <directionalLight position={[10, 10, 5]} intensity={1} />
//         <Model />
//         <OrbitControls />
//       </Suspense>
//     </Canvas>
//   );
// };

const ModelViewer = () => {
  return (
    <Canvas>
      <ambientLight intensity={0.5} />
      <directionalLight position={[10, 10, 5]} intensity={1} />
        <Model />
        <OrbitControls 
        enableZoom={false}
        />
    </Canvas>
  );
};

export default ModelViewer;