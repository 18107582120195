import {useRef, useState} from "react";
// import { useNavigate } from "react-router-dom";
import {Link} from "react-router-dom";

function SignUp(){
    const signUpComponentRef=useRef(null);
    const emailInputComponentRef=useRef(null);
    const passwordInputComponentRef=useRef(null);
    const reEnteredpasswordInputComponentRef=useRef(null);
    const signUpButtonComponentRef=useRef(null);
    const signUpStatus=useRef(null);
    const [passwordVisibility,setPasswordVisibility]=useState('password');
    const [passwordVisibilityIcon,setPasswordVisibilityIcon]=useState('/visibility_off.svg');
    const [signUpStatusMessage,setSignUpStatusMessage]=useState('');
    const [loginLink,setLoginLink]=useState((<></>));

    // const navigate=useNavigate();

    function passwordVisibilityToggle(){
        if(passwordVisibility==='password'){
            setPasswordVisibility('text');
            setPasswordVisibilityIcon('/visibility.svg');
        }else{
            setPasswordVisibility('password');
            setPasswordVisibilityIcon('/visibility_off.svg');
        }
    }


    function sendActivationEmail(){
        const emailAddress=emailInputComponentRef.current.value;
        const password=passwordInputComponentRef.current.value;
        const reEnteredPassword=reEnteredpasswordInputComponentRef.current.value;

        const emailRegex=/^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const passwordRegex=/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

        if(!emailRegex.test(emailAddress)){
            setSignUpStatusMessage('Invalid Email Format');
            setLoginLink((<></>));
        }else if(!passwordRegex.test(password)){
            setSignUpStatusMessage('Password should have at least 8 characters, including 1 uppercase, 1 lowercase, 1 number, and 1 special character (@$!%*?&).');
            setLoginLink((<></>));
        }else if(password!==reEnteredPassword){
            setSignUpStatusMessage('Passwords don\'t match');
            setLoginLink((<></>));
        }else{
            setSignUpStatusMessage('');
            const urlToConnect='https://alterux.in/signUpSubmission';
           // const urlToConnect='http://localhost:8080/signUpSubmission';
            fetch(urlToConnect,{method:'POST',headers:{'Content-Type':'application/json'},body:JSON.stringify({'emailAddress':emailAddress,'password':password})}).then((response)=>{
                return response.text();
            }).then((responseText)=>{

                if(responseText==='account exists'){

                    setSignUpStatusMessage(`Your account is already set up. Please log in to access your account.`);
                    setLoginLink((<Link className='colorGreen0 minimalMargin' to='/login'>Log in</Link>));

                }else if(responseText==='activation email sent'){
    
                    setSignUpStatusMessage(`Activation email has been sent. Please activate account, before`);
                    setLoginLink((<Link className='colorGreen0 minimalMargin' to='/login'>Log in</Link>));

                }else if(responseText==='activation email sent - wait 1 minute'){
    
                    setSignUpStatusMessage('Activation email has been sent. Please wait a minute before retry.');
                    setLoginLink((<Link className='colorGreen0 minimalMargin' to='/login'>Log in</Link>));
    
                }else{
                    setSignUpStatusMessage('Sorry, we are having an issue. Please retry after some time.');
                    setLoginLink((<></>));
                }
            });
    }
    }

    // function navigateToLogin(){
    //     //Write logic to verify OTP and mark account as Activated
    //     navigate('/login');
    // }


return (<div ref={signUpComponentRef} className='W100vw H100vh displayFlex flexDirectionColumn alignItemsCenter justifyContentCenter'>
    
    <div className={'displayFlex flexDirectionColumn'}> 
    <input ref={emailInputComponentRef} className={'signUpInput textAlignCenter placeHolderOpacity borderStyleSolid'} type='text' minLength={8} placeholder='Email address'></input>
       
        <div className='displayFlex alignItemsCenter justifyContentCenter'>
            <input ref={passwordInputComponentRef} className={'signUpInput textAlignCenter placeHolderOpacity borderStyleSolid'} type={passwordVisibility} minLength={8} maxLength={16} placeholder='Password'></input>
            <span onClick={passwordVisibilityToggle}><img className={'W5vw H5vh'} src={passwordVisibilityIcon}></img></span>
            </div>

    <input ref={reEnteredpasswordInputComponentRef} className={'signUpInput textAlignCenter placeHolderOpacity borderStyleSolid'} type={passwordVisibility} minLength={8} maxLength={16} placeholder='Confirm Password'></input>
    </div>


    <button ref={signUpButtonComponentRef} className='signUpButton minimalPadding minimalMargin fontWeightBold borderStyleSolid borderRadius1vw colorBlack backgroundGreen0' onClick={sendActivationEmail}>Sign Up</button>
    
    <p ref={signUpStatus} className='W100vw displayFlex flexDirectionColumn signUpStatus minimalMargin fontWeightBold textAlignCenter'>{signUpStatusMessage}{loginLink}</p>
    
</div>);
}

export default SignUp;

// setSignUpStatusMessage(<>You’ve signed up successfully! <a className='colorGreen0 minimalMargin' href='https://alterux.in/login'>Log in</a></>);