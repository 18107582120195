/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.2 /home/shivarajkumar/Documents/Frontend/reactapp/public/testThreeD.glb 
*/

import React from 'react'
import { useGLTF, PerspectiveCamera } from '@react-three/drei'

export default function Model(props) {
  const { nodes, materials } = useGLTF('/testThreeD.glb')
  return (
    <group {...props} dispose={null}>
      <PerspectiveCamera makeDefault={false} far={100} near={0.1} fov={22.895} position={[6.357, -6.238, 6.21]} rotation={[63, 0, 46]} />
      <group position={[0, 0.1, 2]} rotation={[-10.8, 3, 0.8]}>
        <mesh geometry={nodes.Cube_1.geometry} material={materials.Material} />
        <mesh geometry={nodes.Cube_2.geometry} material={materials['Material.001']} />
      </group>
    </group>
  )
}

useGLTF.preload('/testThreeD.glb')
